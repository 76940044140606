import { useState } from 'react';
import axios from 'axios';
import './style.css';

function Form() {
    
    // const { innerWidth: width, innerHeight: height } = window;
    const [nome, setNome] = useState('')
    const [whats, setWhats] = useState('')
    const [loja, setLoja] = useState('')
    const [email, setEmail] = useState('')

    function funil() {

        const form = document.getElementById('form')
            form.addEventListener('submit', e => {
                e.preventDefault()
            })

            document.getElementById("botao").textContent = "Formulário Enviado!";
            document.getElementById("botao").disabled = true;
    }


    return (
        <div id="formulario" class="container shadow p-3 mb-4 mt-4 rounded">
            <p class="h4">Não perca essa oportunidade!</p>
            <span>Preencha o formulário e compre hoje mesmo!</span><br />
            <form id="form">
                <div class="mb-3">
                    <label class="form-label">Nome</label>
                    <input name="nome" required value={nome} onChange={(e)=>setNome(e.target.value)} class="form-control form-control-sm" type="text" placeholder='Insira seu nome'></input>
                </div>
                <div class="mb-3">
                    <label class="form-label">WhatsApp</label>
                    <input id="whats" name="whats" type="integer" maxLength={11} required value={whats} onChange={(e)=>setWhats(e.target.value)} class="form-control form-control-sm" placeholder='(11) 99999-9999'></input>
                </div>
                <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input id="email" name="email" type="email" required value={email} onChange={(e)=>setEmail(e.target.value)} class="form-control form-control-sm" placeholder='example@example.com'></input>
                </div>
                <div class="mb-3">
                    <label class="form-label">Escolha a loja mais próxima</label>
                    <select name="loja" required value={loja} onChange={(e)=>setLoja(e.target.value)} class="form-select form-select-sm">
                        <option value="região">REGIÃO</option>
                        <option value="ARICANDUVA" >ARICANDUVA</option>
                        <option value="BANDEIRANTES" >BANDEIRANTES</option>
                        <option value="BUTANTÃ" >BUTANTÃ</option>
                        <option value="BARRA FUNDA" >BARRA FUNDA</option>
                        <option value="CAMPINAS" >CAMPINAS</option>
                        <option value="CONGONHAS" >CONGONHAS</option>
                        <option value="GUARAPIRANGA" >GUARAPIRANGA</option>
                        <option value="GUARULHOS" >GUARULHOS</option>
                        <option value="MORUMBI" >MORUMBI</option>
                        <option value="INTERLAGOS" >INTERLAGOS</option>
                        <option value="LAPA" >LAPA</option>
                        <option value="MARGINAL TIÊTE" >MARGINAL TIÊTE</option>
                        <option value="SÃO MIGUEL PAULISTA" >SÃO MIGUEL PAULISTA</option>
                        <option value="OSASCO" >OSASCO</option>
                        <option value="PARAÍSO" >PARAÍSO</option>
                        <option value="FREGUESIA DO Ó" >FREGUESIA DO Ó</option>
                        <option value="TEOTONIO VILELA" >TEÔTONIO VILELA</option>
                        <option value="TATUAPÉ" >TATUAPÉ</option>
                        <option value="SANTANA" >SANTANA</option>
                        <option value="SANTO AMARO" >SANTO AMARO</option>
                        <option value="SANTO ANDRÉ" >SANTO ANDRÉ</option>
                        <option value="SANTOS" >SANTOS</option>
                        <option value="TABOÃO DA SERRA" >TABOÃO DA SERRA</option>
                        <option value="CENTRO/RJ" >CENTRO/RJ</option>
                        <option value="NITERÓI/RJ" >NITERÓI/RJ</option>
                        <option value="MADUREIRA/RJ" >MADUREIRA/RJ</option>
                        <option value="NOVA IGUAÇU/RJ" >NOVA IGUAÇU/RJ</option>
                        <option value="SALVADOR/BA" >SALVADOR/BA</option>
                        <option value="BELO HORIZONTE/MG" >BELO HORIZONTE/MG</option>
                    </select>
                </div>
                <div class="d-grid mx-auto">
                    <button type='submit' onClick={funil} id="botao" class="btn btn-success">Enviar</button>
                </div>
            </form>
        </div>
    );
}

export default Form;